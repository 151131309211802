/* Base styles for the wave element */
#wave {

    transform: translateY(50px); /* Start with additional downward offset */
    transition: opacity 1s ease-in-out, margin-top 1s ease-in-out, transform 1s ease-in-out;
}

/* Animation for the wave element */
@keyframes waveAnimation {
    0% {
        opacity: 0;
        transform: translateY(50px) scale(0.9); /* Start lower and smaller */
    }
    50% {
        opacity: 0.5;
        transform: translateY(10px) scale(1); /* Move up slightly and scale to full size */
    }
    100% {
        opacity: 1;
        transform: translateY(0) scale(1); /* Final position with full opacity */
    }
}

.animate-wave {
    animation: waveAnimation 1.5s ease-in-out forwards;
}
html {
    scroll-behavior: 'smooth';
}