/* LogoLoader.css */
/* LogoLoader.css */

.logo-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust if needed */
}

.loading-rectangle {
  position: absolute;
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  background-color: #000; /* Adjust as needed */
  animation: fadeInOut 1s ease-in-out infinite; /* Example animation */
}

/* Optional: Add animation for the loader */
@keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

/* Container for the logo and loading rectangle */
.logo-container {
    position: relative;
    display: inline-block;
    width: fit-content; /* Adjust based on your logo size */
    height: fit-content; /* Adjust based on your logo size */
}

/* Loading rectangle */
.loading-rectangle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.2); /* Very light transparent gray */
    border-radius: 12px; /* Rounded corners */
    z-index: 1; /* Ensure it appears above the logo */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Loading animation with enhanced aesthetics */
.loading-rectangle::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.1) 75%);
    animation: loadingAnimation 2s infinite; /* Slow animation for a smoother effect */
    border-radius: 50%; /* Circular gradient to give a smooth effect */
}

/* Keyframes for the loading animation */
@keyframes loadingAnimation {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

/* Logo text */
.logo-text {
    visibility: hidden; /* Hidden initially until the font loads */
    z-index: 2; /* Ensure it appears above the loading rectangle */
    opacity: 0 !important;
    transition: opacity 0.3s ease-in;
}

/* Reveal logo text */
.logo-text.loaded {
    visibility: visible;
    opacity: 1 !important;
}

.animate-logo {
    position: relative;
    animation: flickerGlow 5s infinite; /* Apply the flicker glow animation */
  }
  
  /* Flickering glow effect keyframes */
  @keyframes flickerGlow {
    0% {
      opacity: 1;
      text-shadow: 0 0 5px rgba(255, 107, 107, 0.8), 0 0 10px rgba(255, 107, 107, 0.6);
    }
    3% {
      opacity: 0.6;
      text-shadow: 0 0 8px rgba(255, 107, 107, 0.6), 0 0 12px rgba(255, 107, 107, 0.4);
    }
    6% {
      opacity: 1;
      text-shadow: 0 0 5px rgba(255, 107, 107, 0.8), 0 0 10px rgba(255, 107, 107, 0.6);
    }
    9% {
        opacity: 0.6;
        text-shadow: 0 0 5px rgba(255, 107, 107, 0.8), 0 0 10px rgba(255, 107, 107, 0.6);
      }
    15% {
    opacity: 1;
    text-shadow: 0 0 8px rgba(255, 107, 107, 0.6), 0 0 12px rgba(255, 107, 107, 0.4);
    }
    20% {
        opacity: 1;
        text-shadow: 0 0 8px rgba(255, 107, 107, 0.6), 0 0 12px rgba(255, 107, 107, 0.4);
        }
    30% {
        opacity: 1;
        text-shadow: 0 0 8px rgba(255, 107, 107, 0.6), 0 0 12px rgba(255, 107, 107, 0.4);
      }
    100% {
      opacity: 1;
      text-shadow: 0 0 5px rgba(255, 107, 107, 0.8), 0 0 10px rgba(255, 107, 107, 0.6);
    }
  }